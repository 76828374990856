/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $(document).foundation(); // Foundation JavaScript
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        page_id_759: {
            init: function() {

                var initCarousel = function() {
                    $('#slick-carousel .carousel').owlCarousel({
                        navigation: false,
                        slideSPeed: 300,
                        paginationSPeed: 400,
                        singleItem: true,
                        autoHeight: true,
                        transitionStyle: "fade"
                    });
                };
                initCarousel();
                
                //Get the bootstrap carousel going
                // $('#gallery-page-carousel').carousel()
                    //this is the list to choose from on the gallery page
                $(document).on("click", "#galleryCategoryQuery div.galleryCategoryCategory", function() { //$("#galleryInstallationQuery > li").bind('click',function(){               
                    //ev.preventDefault(); if used put ev in parens
                    var Installation_Type = ($(this).data('instaltype'));
                    var GallerySection = ($(this).data('gallery-section')); //inner html of p
                    $('#gallery-carousel').append('<i class="fa fa-spinner fa-spin loading"></i>');
                    $.ajax({
                            url: SJNAjaxObject.ajaxurl,
                            data: {
                                'action': 'return_gallery_pictures', //this is the name of the php function that will handle the call
                                'ajax_nonce': SJNAjaxObject.nonce,
                                'installation_type': Installation_Type,
                                'dataType': "json",
                            },
                            //type: 'GET',
                            success: function(data) {
                                $('#slick-carousel').empty();
                                $('#slick-carousel').append(data); //was html
                                $('.page-header h1').text('Gallery: '+GallerySection);
                                initCarousel();
                                $('.loading').remove(); 
                            }, //end success
                            error: function(errorThrown) {
                                console.log(errorThrown);
                            }
                        }); //end ajax
                }); //end bind
            }
        },
        page_id_294: {
            init: function() {

            	var initCarousel = function() {
            		$('#slick-carousel .carousel').owlCarousel({
            			navigation: false,
            			slideSPeed: 300,
            			paginationSPeed: 400,
            			singleItem: true,
            			autoHeight: true,
            			transitionStyle: "fade"
            		});
            	};
            	initCarousel();
            	
                //Get the bootstrap carousel going
                // $('#gallery-page-carousel').carousel()
                    //this is the list to choose from on the gallery page
                $(document).on("click", "#galleryCategoryQuery div.galleryCategoryCategory", function() { //$("#galleryInstallationQuery > li").bind('click',function(){               
                    //ev.preventDefault(); if used put ev in parens
                    var Installation_Type = ($(this).data('instaltype'));
                    var GallerySection = ($(this).data('gallery-section')); //inner html of p
                    $('#gallery-carousel').append('<i class="fa fa-spinner fa-spin loading"></i>');
                    $.ajax({
                            url: SJNAjaxObject.ajaxurl,
                            data: {
                                'action': 'return_gallery_pictures', //this is the name of the php function that will handle the call
                                'ajax_nonce': SJNAjaxObject.nonce,
                                'installation_type': Installation_Type,
                                'dataType': "json",
                            },
                            //type: 'GET',
                            success: function(data) {
                                $('#slick-carousel').empty();
                                $('#slick-carousel').append(data); //was html
                                $('.page-header h1').text('Gallery: '+GallerySection);
                                initCarousel();
                                $('.loading').remove();
                            }, //end success
                            error: function(errorThrown) {
                                console.log(errorThrown);
                            }
                        }); //end ajax
                }); //end bind
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.